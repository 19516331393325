
import { SECOND } from '~/vars/api';

export default {
  computed: {
    homeURL() {
      return this.$api.commons.homeURL();
    },
  },
  methods: {
    // onloadIFrame(event) {
    //   setTimeout(() => {
    //     console.log(event);
    //     console.log(event.target);
    //     console.log(event.target.contentWindow);
    //     console.log(event.target.querySelector('#hs-cmsf-branding'));
    //     //   console.log(event.target.contentWindow.document.body);
    //   }, 5 * SECOND);
    // },
  },
  mounted() {
    // console.log(document.querySelector('div#hs-cmsf-branding'));
    // document.getElementById('indexFrame').onload = this.onloadIFrame;
  },
};
